




































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import AAuditQuestionAdditionalContentBox from "@/components/controls/AAuditQuestionAdditionalContentBox.vue";
import { idable } from "@auditcloud/shared/lib/types/common";
import { QuestionSearchResultMap } from "@auditcloud/shared/lib/types/Audit/types";
import AAuditItemNoteDialog from "@/components/dialogs/AAuditItemNoteDialog.vue";
import AAuditItemTagsDialog from "@/components/dialogs/AAuditItemTagsDialog.vue";
import { api as auditApi } from "../../store/modules/audit";
import { Action, Getter } from "vuex-class";
import { isEqual, uniqBy } from "lodash";
import { api as configApi } from "@/store/modules/configuration";
import AIconTogglebar from "@/components/controls/AIconTogglebar.vue";
import AQuestionContent from "../controls/AQuestionContent.vue";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";
import { AuditItemTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import {
  AuditItem,
  CategoryLevel,
  ChapterRefType,
} from "@auditcloud/shared/lib/schemas";

import { sortable_chapter } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import {
  FILTER_CATEGORY,
  FILTER_CHAPTER,
  FILTER_IMPORTANCE,
  FILTER_TAGS,
  FILTER_VDA_PROCESSES,
  FILTER_VDA_QUESTION_SCOPE,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import {
  AuditItemProperties,
  PreparationStates,
} from "@/store/modules/audit/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";

@Component({
  components: {
    ATableItem,
    AAuditQuestionAdditionalContentBox,
    AAuditItemNoteDialog,
    AAuditItemTagsDialog,
    ASnippetCategoryRef,
    AIconTogglebar,
    AQuestionContent,
  },
})
export default class AAuditPreparationItem extends Vue {
  get chapterGroups() {
    const chapters = this.auditItem.question.chapters;
    const uniqChaptersById = uniqBy(chapters, "standardId");
    return uniqChaptersById.map(chapter => {
      return {
        standardName: chapter.standardName,
        chapters: chapters.filter(c => c.standardId === chapter.standardId),
      };
    });
  }

  chapterFilterId(chapterRefType: ChapterRefType): string {
    return sortable_chapter(chapterRefType);
  }
  isImportanceFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_IMPORTANCE, value);
  }
  toggleImportance(importance: string) {
    this.emitToggleFilter({
      aggregationId: FILTER_IMPORTANCE,
      value: importance,
    });
  }

  isChapterFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_CHAPTER, value);
  }
  toggleChapter(chapterRefType: ChapterRefType) {
    this.emitToggleFilter({
      aggregationId: FILTER_CHAPTER,
      value: this.chapterFilterId(chapterRefType),
    });
  }
  isVDAProcessFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_VDA_PROCESSES, value);
  }
  toggleVDAProcess(vdaProcessStep: number) {
    this.emitToggleFilter({
      aggregationId: FILTER_VDA_PROCESSES,
      value: vdaProcessStep,
    });
  }
  isVDAQuestionScopeFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_VDA_QUESTION_SCOPE, value);
  }
  toggleVDAQuestionScope(vdaQuestionScope: string) {
    this.emitToggleFilter({
      aggregationId: FILTER_VDA_QUESTION_SCOPE,
      value: vdaQuestionScope,
    });
  }

  isCategoryFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_CATEGORY, value);
  }
  toggleCategory(categoryId: string) {
    this.emitToggleFilter({
      aggregationId: FILTER_CATEGORY,
      value: categoryId,
    });
  }

  isTagsFilterActive(value: Filter["value"]): boolean {
    return this.isActive(FILTER_TAGS, value);
  }
  toggleTags(tag: string) {
    this.emitToggleFilter({
      aggregationId: FILTER_TAGS,
      value: tag,
    });
  }

  emitToggleFilter(filter: Filter) {
    if (!this.allowFiltering) {
      return;
    }
    this.$emit("toggle-filter", filter);
  }

  isActive(aggregationId: string, value: Filter["value"]): boolean {
    const filter = { aggregationId, value };
    return (
      typeof this.activeFilters.find(activeFilter =>
        isEqual(filter, activeFilter)
      ) !== "undefined"
    );
  }

  get itemTypeColor(): string {
    return this.mappedAuditItemTypes[this.auditItem.question.type].color;
  }

  get itemTypeColorWithAlpha(): string {
    const color = this.itemTypeColor;
    const isHexColor =
      typeof color === "string" && color.length === 7 && color[0] === "#";
    return isHexColor ? color + "33" : color;
  }

  @Prop({
    type: Object,
    required: true,
  })
  auditItem!: idable<AuditItem>;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableIgnoredAppearance!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disableActions!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  isSelected!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  bulkEditMode!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showStandardNames!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  activeFilters!: Filter[];

  @Prop({ type: Boolean, default: true })
  readonly allowEditing!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly allowFiltering!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly allowSelection!: boolean;

  @Prop({ type: String, required: true })
  readonly filteredText!: string;

  @Prop({ type: Object, required: true })
  readonly searchResultsByAuditItemIds!: QuestionSearchResultMap;

  @Prop({ type: Object, default: null })
  readonly auditItemProperties!: AuditItemProperties | null;

  isEditingNote: boolean = false;
  isEditingTags: boolean = false;
  isToggling = false;
  isTogglingSelfAssessment = false;
  currentToggle: string = "";

  get questionInSelfassessment(): boolean {
    return (
      this.selfAssessmentFeatureEnabled &&
      this.selfAssessmentIncluded &&
      this.auditItemProperties?.isSelfAssessmentEnabled === true
    );
  }

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  @Getter(auditApi.getters.getSelfAssessmentIncluded, {
    namespace: auditApi.namespace,
  })
  selfAssessmentIncluded!: boolean;

  @Getter(auditApi.getters.getMappedAuditItemTypes, {
    namespace: auditApi.namespace,
  })
  mappedAuditItemTypes!: AuditItemTypeMap;

  @Getter(auditApi.getters.getAuditCategorySetId, {
    namespace: auditApi.namespace,
  })
  categorySetId!: string;

  @Getter(auditApi.getters.getCategoryLevel, {
    namespace: auditApi.namespace,
  })
  categoryLevel!: CategoryLevel;

  @Getter(auditApi.getters.getIsAuditItemNoteVisible, {
    namespace: auditApi.namespace,
  })
  noteVisible!: boolean;

  @Getter(auditApi.getters.getPreparationState, {
    namespace: auditApi.namespace,
  })
  getPreparationState!: PreparationStates;

  @Getter(auditApi.getters.getAuditIsBulkUpdating, {
    namespace: auditApi.namespace,
  })
  auditIsBulkUpdating!: boolean;

  @Getter(auditApi.getters.getIsRestrictPreparationToCategories, {
    namespace: auditApi.namespace,
  })
  isRestrictPreparationToCategories!: boolean;

  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }

  get hasHint(): boolean {
    const hint = this.$ct(this.auditItem.question.hint ?? "").trim();
    return hint !== "";
  }

  get searchResult() {
    return this.searchResultsByAuditItemIds[this.auditItem.id];
  }

  get hasAuditItemProperties() {
    return this.auditItemProperties !== null;
  }

  toggleSelection(auditItemId: AuditItemWithId["id"]) {
    if (!this.allowSelection) {
      return;
    }
    this.$emit("toggle-audit-item-selection", auditItemId);
  }

  @Action(auditApi.actions.manuallyAddToConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyAddToConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyRemoveFromConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyRemoveFromConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  toggleIgnore(auditItemId: AuditItemWithId["id"]) {
    if (!this.hasAuditItemProperties) {
      return;
    }
    const handler = this.auditItemProperties?.isConsidered
      ? this.manuallyRemoveFromConsideredAuditItems
      : this.manuallyAddToConsideredAuditItems;
    this.isToggling = true;
    handler([auditItemId])
      .then(() => {})
      .catch(err => {
        console.error("toggleIgnore failed: ", err);
      })
      .finally(() => {
        this.isToggling = false;
      });
  }

  @Action(auditApi.actions.manuallyAddAuditItemsToSelfAssessment, {
    namespace: auditApi.namespace,
  })
  manuallyAddAuditItemsToSelfAssessment!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyRemoveAuditItemsFromSelfAssessment, {
    namespace: auditApi.namespace,
  })
  manuallyRemoveAuditItemsFromSelfAssessment!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Getter(auditApi.getters.getMappedNotes, {
    namespace: auditApi.namespace,
  })
  mappedNotes!: Map<AuditItemWithId["id"], string[]>;

  get isSelfAssessmentEnabled() {
    const isSelfAssessmentEnabled =
      this.auditItemProperties?.isSelfAssessmentEnabled;

    return isSelfAssessmentEnabled ?? false;
  }

  get isSelfAssessmentStep() {
    return this.getPreparationState === "self-assessment-selection";
  }

  get isManualSelectionStep() {
    return this.getPreparationState === "manual-selection";
  }

  get isIgnored() {
    if (!this.hasAuditItemProperties) {
      return false;
    }
    const isConsidered = this.auditItemProperties?.isConsidered;

    return !(isConsidered ?? false);
  }

  get questionNotes(): string[] {
    return this.mappedNotes.get(this.auditItem.id) ?? [];
  }

  toggleIgnoreSelfAssessment(auditItemId: AuditItemWithId["id"]) {
    if (!this.allowEditing) {
      return;
    }
    this.isTogglingSelfAssessment = true;
    const promise = this.isSelfAssessmentEnabled
      ? this.manuallyRemoveAuditItemsFromSelfAssessment([auditItemId])
      : this.manuallyAddAuditItemsToSelfAssessment([auditItemId]);
    promise
      .then(() => {
        console.log("toggleIgnoreSelfAssessment done...");
      })
      .catch(err => {
        console.error("toggleIgnoreSelfAssessment failed...", err);
      })
      .finally(() => {
        this.isTogglingSelfAssessment = false;
      });
  }
}
