var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-end",class:_vm.activeUiFilters.length > 0 ? 'mb-1' : ''},[_c('v-menu',{attrs:{"attach":"","nudge-bottom":"0","close-on-click":true,"close-on-content-click":false,"nudge-top":-4,"max-height":"calc(100vh - 355px)","max-width":300,"rounded":"","left":"","bottom":"","offset-y":"","ripple":false,"transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-text-field',{staticClass:"mr-1",staticStyle:{"max-width":"250px"},attrs:{"value":_vm.fulltextSearchValue,"placeholder":_vm.$t('common.search'),"outlined":"","dense":"","hide-details":"","clearable":""},on:{"input":_vm.setFulltextSearchDebounced,"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==51){ return null; }return _vm.filterMode($event)}}}),_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"text":"","ripple":false,"elevation":"0","min-width":"40px","min-height":"40px"},on:{"click":function($event){_vm.filterMenu = false}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-1",attrs:{"color":_vm.filterMenu ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(_vm.filterMenu ? "mdi-close" : "mdi-filter")+" ")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[(_vm.filterMenu)?_c('div',{staticClass:"d-flex flex-column pa-0",staticStyle:{"background-color":"#fff","position":"relative"}},[_c('div',{staticClass:"d-flex flex-column pa-3 white",staticStyle:{"position":"sticky","top":"0","z-index":"3","min-height":"64px"}},[_c('div',{staticClass:"\n              d-flex\n              justify-space-between\n              align-center\n              font-weight-bold\n              text-body-1\n              secondary--text\n            ",staticStyle:{"height":"40px"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$ft("components.widgets.search_filter.available_filters"))+" ")]),(_vm.activeUiFilters.length > 0)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 no-uppercase ml-2",attrs:{"min-width":"40px","height":"40px","text":"","color":"blue lighten-2","ripple":false,"elevation":"0"},on:{"click":_vm.clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-off")])],1)]}}],null,false,158494159)},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.search_filter.clear_all_filters"))+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex blue--text text-caption text-no-wrap"})]),_c('div',{staticClass:"d-flex justify-space-between flex-wrap",staticStyle:{"min-width":"100px","position":"relative"}},[_vm._l((_vm.preparedAggregations),function(ref,idx){
var id = ref.id;
var name = ref.name;
var buckets = ref.buckets;
var collapsedState = ref.collapsedState;
var activeBucketCount = ref.activeBucketCount;
return [_c('div',{key:'aggregation-' + id,staticClass:"\n                d-flex\n                justify-space-between\n                secondary--text\n                text-caption text-no-wrap\n                px-4\n                white\n              ",staticStyle:{"position":"sticky","top":"64px","z-index":"3","width":"100%"}},[_c('div',{staticClass:"\n                  flex-no-wrap\n                  text-body-2\n                  overflow-hidden\n                  font-weight-bold\n                ",staticStyle:{"min-height":"24px"}},[_vm._v(" "+_vm._s(_vm.$ft(name))+" ")]),_c('div',{staticClass:"d-flex"},[(activeBucketCount > 0)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"x-small":"","min-width":"24px","height":"24px","text":"","color":"blue lighten-2","ripple":false,"elevation":"0"},on:{"click":function($event){return _vm.clearAllFiltersOfType(id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter-off")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.search_filter.clear_filter_n", { n: _vm.$t(name), }))+" ")]):_vm._e(),(collapsedState)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"x-small":"","min-width":"24px","height":"24px","text":"","color":"blue lighten-2","ripple":false,"elevation":"0"},on:{"click":function($event){return _vm.toggleAggregationCollaps(id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(collapsedState === "open" ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(collapsedState === "open" ? "Einklappen" : "Ausklappen")+" ")]):_vm._e()],1)]),_c('div',{key:'container-' + id,staticClass:"d-flex flex-column overflow-hidden flex-grow-1 white",class:{
                'mb-3': idx === _vm.preparedAggregations.length - 1,
              },staticStyle:{"min-width":"100%"}},[_vm._l((buckets),function(bucket){return _c('div',{key:("bucket-" + id + "-" + (typeof bucket.value) + "-" + (bucket.value))},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('div',{staticClass:"\n                      d-flex\n                      align-center\n                      justify-space-between\n                      text-caption\n                      px-4\n                      pointer\n                    ",class:{
                      'py-2': _vm.isTouchDevice,
                      'grey lighten-4': hover,
                      'text--disabled':
                        !bucket.active && bucket.resultCount === 0,
                    },staticStyle:{"width":"100%"},on:{"click":function($event){(bucket.active || bucket.resultCount !== 0) &&
                        _vm.toggleClickedFilter({
                          aggregationId: id,
                          value: bucket.value,
                        })}}},[_c('div',{staticClass:"d-flex align-center flex-no-wrap overflow-hidden"},[_c('v-icon',{staticClass:"mr-1",class:bucket.active
                            ? ['primary--text']
                            : ['grey--text text--lighten-2'],attrs:{"small":""}},[_vm._v(" "+_vm._s(bucket.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank")+" ")]),_c('span',{staticClass:"text-no-wrap",class:bucket.active ? ['font-weight-bold'] : ['']},[_vm._v(" "+_vm._s(_vm.$ft(bucket.name))+" ")])],1),_c('span',{staticClass:"ml-1 text-caption font-weight-light grey--text"},[_vm._v(" ("+_vm._s(bucket.resultCount)+") ")])])]}}],null,true)})],1)}),(collapsedState)?_c('div',{key:'more-' + idx,staticClass:"text-caption blue--text text--lighten-2 px-4"},[_c('v-btn',{staticClass:"no-uppercase",attrs:{"x-small":"","min-width":"24px","block":"","height":"24px","text":"","color":"blue lighten-2","ripple":false,"elevation":"0"},on:{"click":function($event){return _vm.toggleAggregationCollaps(id)}}},[_vm._v(" "+_vm._s(collapsedState === "open" ? "Weniger" : "Weitere")+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(collapsedState === "open" ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)],1):_vm._e(),(idx !== _vm.preparedAggregations.length - 1)?_c('v-divider',{key:'divider-' + idx,staticClass:"ma-0 my-3"}):_vm._e()],2)]}),(_vm.preparedAggregations.length === 0)?_c('div',{staticClass:"pa-3"},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.search_filter.no_filter"))+" ")]):_vm._e()],2)]):_vm._e()])],1),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap-reverse"},[(_vm.activeUiFilters.length > 1)?_c('v-btn',{staticClass:"text-caption no-uppercase",attrs:{"text":"","x-small":"","max-height":"24","min-height":"24","plain":""},on:{"click":_vm.clearFilters}},[_vm._v(" "+_vm._s(_vm.$ft("components.widgets.search_filter.clear_filters"))+" ")]):_vm._e(),_vm._l((_vm.activeUiFilters),function(ref){
                            var filter = ref.filter;
                            var aggregationName = ref.aggregationName;
                            var bucketName = ref.bucketName;
return _c('AColoredChip',{key:("active-filter-chip-" + (filter.aggregationId) + "-" + (typeof filter.value) + "-" + (filter.value)),staticClass:"ml-1 mb-1",attrs:{"a-style":"light","small":"","label":"","color":"#333333","close":"","title":((_vm.$ft(aggregationName)) + ": " + (_vm.$ft(bucketName)))},on:{"click:close":function($event){return _vm.toggleClickedFilter(filter)}}},[_vm._v(" "+_vm._s(_vm.$ft(bucketName))+" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }