




















































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";
import { api } from "@/store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";
import { extractSignificantCategories } from "@/store/modules/audit/utils";
import { Getter } from "vuex-class";
import {
  AuditItemCategoryMap,
  typeIsCategoryLevelTree,
  CategoryLevelOptions,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
// import { Question, validateQuestion } from "../types";
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VCheckbox,
  VSpacer,
} from "vuetify/lib";
import { AuditItem, CategoryLevel } from "@auditcloud/shared/lib/schemas";
import {
  normalizeAuditItemNoForSorting,
  array_cmp,
} from "@auditcloud/shared/lib/utils/comperators";
import { idable } from "@auditcloud/shared/lib/types/common";
import { QuestionSearchResultMap } from "@auditcloud/shared/lib/types/Audit/types";
import AAuditPreparationItem from "@/components/widgets/AAuditPreparationItem.vue";
import { AuditItemTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { AuditItemProperties } from "@/store/modules/audit/types";
import { clone, Dictionary, fromPairs, intersection } from "lodash";

const NO_CATEGORY = "Unbekannt";
interface GroupedQuestion {
  categoryIgnored: boolean;
  id: string;
  name: string;
  questions: idable<AuditItem>[];
  checked: boolean;
  indeterminate: boolean;
}

@Component({
  components: {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VCheckbox,
    VSpacer,
    ATableItem,
    ASnippetCategoryRef,
    AAuditPreparationItem,
  },
})
export default class AQuestionListGroups extends Vue {
  @Prop({
    default: () => [],
    type: Array,
    validator(val) {
      return val instanceof Array; // && val.every((v: any) => validateQuestion(v));
    },
  })
  readonly questions!: AuditItemWithId[];

  @Prop({
    default: [],
    type: Array,
    validator(val) {
      return (
        val instanceof Array && val.every((v: any) => typeof v === "string")
      );
    },
  })
  readonly value!: string[];

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly readonly!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly allowSelection!: boolean;

  @Prop({
    required: true,
    type: Boolean,
  })
  readonly bulkEditMode!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  activeFilters!: Filter[];

  @Getter(api.getters.getAuditItemProperties, {
    namespace: api.namespace,
  })
  auditItemPropertiesMap!: Map<AuditItemWithId["id"], AuditItemProperties>;

  @Getter(api.getters.getAuditCategoryMapping, { namespace: api.namespace })
  mappedCategories!: AuditItemCategoryMap;

  @Getter(api.getters.getCategoryLevel, { namespace: api.namespace })
  categoryLevel!: CategoryLevel;

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  @Getter(api.getters.getSelfAssessmentIncluded, { namespace: api.namespace })
  selfAssessmentIncluded!: boolean;

  @Getter(api.getters.getMappedAuditItemTypes, { namespace: api.namespace })
  mappedAuditItemTypes!: AuditItemTypeMap;

  @Getter(api.getters.getAuditCategorySetId, { namespace: api.namespace })
  categorySetId!: string;

  @Getter(api.getters.getFullTextSearch, { namespace: api.namespace })
  filteredText!: string;

  @Getter(api.getters.getSearchResultsByAuditItemIds, {
    namespace: api.namespace,
  })
  searchResultsByAuditItemIds!: QuestionSearchResultMap;

  @Getter(api.getters.getAuditItemProperties, { namespace: api.namespace })
  auditItemProperties!: Map<AuditItemWithId["id"], AuditItemProperties>;

  @Getter(api.getters.getAuditStatus, {
    namespace: api.namespace,
  })
  auditStatus!: string | null;

  @Prop({
    type: String,
    default: null,
    validator(value: unknown) {
      return (
        value === null ||
        value === "manual-selection" ||
        value === "self-assessment-selection"
      );
    },
  })
  preparationStep!: "manual-selection" | "self-assessment-selection" | null;

  get tagsUsedEverywhere(): Dictionary<string[]> {
    const pairs = this.groupedAuditItems.map(auditItemGroup => {
      const tagsPerQuestion = auditItemGroup.questions.map(
        question => question.tags
      );
      const tagsUsedInWholeGroup = intersection(...tagsPerQuestion);

      return [auditItemGroup.id, tagsUsedInWholeGroup];
    });
    return fromPairs(pairs);
  }

  get isManualSelectionStep() {
    return this.preparationStep === "manual-selection";
  }

  get isSelfAssessmentStep() {
    return this.preparationStep === "self-assessment-selection";
  }

  get selectedQuestions() {
    return this.value;
  }
  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }
  get groupedAuditItems(): GroupedQuestion[] {
    const res: GroupedQuestion[] = [];
    const selectedValues = this.value;

    this.questions.forEach(auditItem => {
      const categoryIds = extractSignificantCategories(
        auditItem.question.categoryRef,
        this.categoryLevel
      );

      let categoryId;
      if (
        this.categoryLevel === CategoryLevelOptions.Root ||
        typeIsCategoryLevelTree(this.categoryLevel)
      ) {
        categoryId = categoryIds[0];
      }
      if (this.categoryLevel === CategoryLevelOptions.Leaf) {
        categoryId = categoryIds[categoryIds.length - 1];
      }

      const category = this.mappedCategories[categoryId];
      const name = this.$ct(category ? category.name : NO_CATEGORY);
      const group = res.find(gq => gq.id === categoryId);
      if (group) {
        group.categoryIgnored =
          group.categoryIgnored &&
          !(this.auditItemProperties.get(auditItem.id)?.isConsidered ?? true);
        group.questions.push(auditItem);
      } else {
        res.push({
          categoryIgnored: !(
            this.auditItemProperties.get(auditItem.id)?.isConsidered ?? true
          ),
          id: categoryId,
          name,
          questions: [auditItem],
          indeterminate: false,
          checked: false,
        });
      }
    });
    res.sort((lhs, rhs) => {
      const startsWithNumber = /^\s*(\d+)/;
      const lhsNo = startsWithNumber.exec(lhs.name);
      const rhsNo = startsWithNumber.exec(rhs.name);
      if (lhsNo && rhsNo) {
        return parseInt(lhsNo[1], 10) - parseInt(rhsNo[1], 10);
      } else {
        return lhs.name === rhs.name ? 0 : lhs.name < rhs.name ? -1 : 1;
      }
    });

    res.forEach(group => {
      const every = group.questions.every(q => selectedValues.includes(q.id));
      const some = group.questions.some(q => selectedValues.includes(q.id));

      group.checked = every;
      group.indeterminate = every !== some;
    });

    return res.map(group => {
      group.questions.sort((lhs, rhs) => {
        const lhsNo = normalizeAuditItemNoForSorting(lhs.question.no);
        const rhsNo = normalizeAuditItemNoForSorting(rhs.question.no);
        return array_cmp(lhsNo, rhsNo);
      });
      return group;
    });
  }

  enabledQuestions(item: GroupedQuestion) {
    return this.questions.filter(
      q =>
        this.auditItemProperties.get(q.id)?.isConsidered &&
        item.questions.find(i => i.id === q.id)
    );
  }

  selfAssessmentEnabledQuestions(item: GroupedQuestion) {
    return this.questions.filter(
      q =>
        this.auditItemProperties.get(q.id)?.isSelfAssessmentEnabled &&
        item.questions.find(i => i.id === q.id)
    );
  }

  selectQuestions(questionIds: string[]): void {
    this.$emit("input", questionIds);
  }

  selectGroup(checked: boolean, questions: idable<AuditItem>[]) {
    const selectedValues = this.value;
    const questionIds = questions.map(v => v.id);

    let result = clone(selectedValues);
    if (checked) {
      questionIds.forEach((questionId: string) => {
        if (!result.includes(questionId)) {
          result.push(questionId);
        }
      });
    } else {
      result = selectedValues.filter(v => !questionIds.includes(v));
    }
    this.$emit("input", result);
  }
}
