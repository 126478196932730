










































































































































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AColoredChip from "@/components/snippets/AColoredChip.vue";
import { extractQueries } from "@auditcloud/shared/lib/utils/filter/FilterUtils";
import { State } from "vuex-class";

import { api as appApi } from "@/store/modules/app";
import {
  Aggregation,
  Bucket,
  Filter,
  FilterUiApi,
  UiFilter,
} from "@auditcloud/shared/lib/utils/filter/types";
import {
  activeUiFilters,
  typeIsFilterUiApi,
} from "@auditcloud/shared/lib/utils/filter/utils";

import { debounce, isArray, isString } from "lodash";

import { getterNs, mutationNs } from "@/utils/VuexHelper";

@Component({
  components: { AColoredChip },
})
export default class ASearchFilter extends Vue {
  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }
  filterMenu: boolean = false;
  searchFieldFocus: boolean = false;

  @Prop({
    required: true,
    validator: typeIsFilterUiApi,
    type: Object,
  })
  api!: FilterUiApi;

  get aggregations(): Aggregation[] {
    const api = this.api;
    const getAggregations = this.$store.getters[
      getterNs(api, api.getters.getAggregations)
    ] as unknown;

    if (isArray(getAggregations)) {
      return getAggregations;
    } else {
      console.error(
        "Expect that getAggregations returns a array got",
        getAggregations
      );
      return [];
    }
  }

  get fulltextSearchValue(): string {
    const api = this.api;
    const getFulltextSearch = this.$store.getters[
      getterNs(api, api.getters.getFulltextSearch)
    ] as unknown;

    if (isString(getFulltextSearch)) {
      return getFulltextSearch;
    } else {
      console.error(
        "Expect that getFulltextSearch returns a string got",
        getFulltextSearch
      );
      return "";
    }
  }

  toggleClickedFilter(filter: Filter): void {
    const api = this.api;
    this.$store.commit(mutationNs(api, api.mutations.TOGGLE_FILTER), filter, {
      root: true,
    });
  }
  setFilters(filters: Filter[]): void {
    const api = this.api;
    this.$store.commit(mutationNs(api, api.mutations.SET_FILTERS), filters, {
      root: true,
    });
  }

  clearAllFiltersOfType(aggregationId: Filter["aggregationId"]): void {
    const api = this.api;
    this.$store.commit(
      mutationNs(api, api.mutations.CLEAR_FILTER_FOR_AGGREGATIONS),
      aggregationId,
      { root: true }
    );
  }

  clearAllFilters(): void {
    const api = this.api;
    this.$store.commit(
      mutationNs(api, api.mutations.CLEAR_ALL_FILTERS),
      undefined,
      { root: true }
    );
  }

  setFulltextSearch(needle: string): void {
    const api = this.api;
    this.$store.commit(
      mutationNs(api, api.mutations.SET_FULLTEXT_SEARCH),
      needle,
      { root: true }
    );
  }

  clearFilters() {
    this.clearAllFilters();
    this.setFulltextSearch("");
  }
  get setFulltextSearchDebounced(): (val: string | null) => void {
    return debounce((val: string | null) => {
      if (val === null) {
        val = "";
      }
      const { filters, fullText } = extractQueries(val, this.aggregations);
      this.setFulltextSearch(fullText);

      if (filters.length) {
        this.setFilters(filters);
      }
    }, 250);
  }

  @State(appApi.state.drawerVisible, {
    namespace: appApi.namespace,
  })
  drawerVisible!: boolean;

  windowWidth: number = 0;

  uncollapsedAggregations: Array<Aggregation["id"]> = [];

  toggleAggregationCollaps(aggregationId: Aggregation["id"]) {
    if (this.uncollapsedAggregations.includes(aggregationId)) {
      this.uncollapsedAggregations = this.uncollapsedAggregations.filter(
        uncollapsedAggregationId => uncollapsedAggregationId !== aggregationId
      );
    } else {
      this.uncollapsedAggregations.push(aggregationId);
    }
  }

  onWindowResize() {
    this.windowWidth = window.innerWidth;
  }
  mounted() {
    this.setFulltextSearch("");
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.onWindowResize);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  get menuWidth() {
    // 256 is the default v-navigation-drawer width
    const totalDrawerWidth = this.drawerVisible ? 256 : 0;
    const padding = 50;
    return this.windowWidth - padding - totalDrawerWidth;
  }

  get preparedAggregations(): Array<
    Aggregation & {
      collapsedState: false | "open" | "closed";
      activeBucketCount: number;
    }
  > {
    console.log("preparedAggregations", this.aggregations);
    const uncollapsedAggregations = this.uncollapsedAggregations;

    const result = this.aggregations.map(aggregation => {
      const collapsedState: "open" | "closed" | false =
        aggregation.buckets.length <= 10
          ? false
          : uncollapsedAggregations.includes(aggregation.id)
          ? "open"
          : "closed";
      const activeBucketCount = aggregation.buckets.reduce(
        (p, bucket) => (bucket.active ? p + 1 : p),
        0
      );

      return {
        ...aggregation,
        buckets:
          collapsedState === "closed"
            ? aggregation.buckets.slice(0, 10)
            : aggregation.buckets,
        collapsedState,
        activeBucketCount,
      };
    });

    return result;
  }

  get activeUiFilters(): Array<UiFilter> {
    return activeUiFilters(this.aggregations);
  }

  filterMode() {
    // TODO ACS-2144
    // if (this.value.search === undefined || this.value.search === "") {
    //   this.filterMenu = true;
    // }
  }
}
