





















/* tslint:disable:no-console */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AColoredChip extends Vue {
  get textColor() {
    if (this.aStyle === "filled") {
      return "white";
    } else {
      return this.color;
    }
  }
  get backgroundColor() {
    if (this.aStyle === "filled") {
      return this.color;
    } else if (this.aStyle === "light") {
      return this.color + "33";
    } else {
      return this.color;
    }
  }

  @Prop({
    type: String,
    required: true,
  })
  readonly color!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly truncate!: boolean;

  @Prop({
    type: String,
    default: "filled",
  })
  readonly aStyle!: "filled" | "light" | "outlined";
}
