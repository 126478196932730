var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.allowEditing && _vm.isEditingNote)?_c('AAuditItemNoteDialog',{attrs:{"audit-item-id":_vm.auditItem.id,"is-open":_vm.isEditingNote},on:{"close":function($event){_vm.isEditingNote = false}}}):_vm._e(),(_vm.allowEditing && _vm.isEditingTags)?_c('AAuditItemTagsDialog',{attrs:{"audit-item-ids":[_vm.auditItem.id],"is-open":_vm.isEditingTags},on:{"close":function($event){_vm.isEditingTags = false}}}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex"},[_c('div',{class:[
          { 'ml-1': _vm.$vuetify.breakpoint.smAndDown, primary: _vm.isSelected } ],staticStyle:{"width":"4px","min-width":"4px","min-height":"100%"},style:({
          'background-color': !_vm.allowSelection
            ? 'white'
            : _vm.isSelected
            ? '#0597a7'
            : hover && !_vm.isTouchDevice
            ? '#0597a7'
            : 'white',
        })}),_c('ATableItem',{staticClass:"py-1 mb-0",class:{ pointer: _vm.allowSelection },staticStyle:{"width":"100%"},attrs:{"nopadding":"","noborder":"","noheaderborder":"","header-background-color":"","custom-style":{
          'background-color': !_vm.allowSelection
            ? 'white'
            : hover && !_vm.isTouchDevice
            ? '#F6F6F6'
            : 'white',
        }},on:{"click":function($event){return _vm.toggleSelection(_vm.auditItem.id)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.allowSelection)?_c('div',{staticClass:"mr-4 mb-1 align-end"},[_c('div',{staticStyle:{"width":"20px","height":"20px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly && (hover || _vm.isTouchDevice || _vm.bulkEditMode)),expression:"!readonly && (hover || isTouchDevice || bulkEditMode)"}]},[_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"size":"20","color":_vm.isSelected ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.isSelected ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1)])]):_vm._e(),_c('div',{class:[
              'd-flex align-end font-weight-regular flex-grow-1',
              { 'opacity-4': _vm.isIgnored } ]},[_c('div',{staticClass:"mr-3 mb-1"},[_c('div',[_vm._v("#"+_vm._s(_vm.auditItem.question.no))])]),_c('div',{staticClass:"mr-3 mb-1"},[_c('v-chip',{staticClass:"ma-0 px-2 text-caption",class:_vm.isImportanceFilterActive(_vm.auditItem.question.type)
                    ? 'white--text'
                    : '',staticStyle:{"max-height":"20px"},style:({
                  color: _vm.isImportanceFilterActive(_vm.auditItem.question.type)
                    ? ''
                    : _vm.mappedAuditItemTypes[_vm.auditItem.question.type].color,
                  cursor: _vm.allowFiltering ? 'pointer' : 'default',
                }),attrs:{"label":"","small":"","title":_vm.$ft(
                    _vm.mappedAuditItemTypes[_vm.auditItem.question.type].description
                  ),"color":_vm.isImportanceFilterActive(_vm.auditItem.question.type)
                    ? _vm.itemTypeColor
                    : _vm.itemTypeColorWithAlpha,"ripple":_vm.allowFiltering},on:{"click":function($event){$event.stopPropagation();return _vm.toggleImportance(_vm.auditItem.question.type)}}},[_vm._v(" "+_vm._s(_vm.mappedAuditItemTypes[_vm.auditItem.question.type].short)+" ")])],1),_c('div',{staticClass:"d-flex mr-3 mb-1",class:{ 'a-snippet-category-ref': _vm.allowFiltering },attrs:{"ripple":_vm.allowFiltering},on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory(_vm.auditItem.question.categoryRef[0])}}},[_c('ASnippetCategoryRef',{attrs:{"filter":false,"category-ref":_vm.auditItem.question.categoryRef,"category-set-id":_vm.categorySetId,"category-level":_vm.categoryLevel,"show-categories-in-single-row":true,"label":true,"custom-classes":[
                  'ma-0 px-2 text-caption max-height-20px',
                  _vm.isCategoryFilterActive(_vm.auditItem.question.categoryRef[0])
                    ? ['white--text']
                    : [''] ],"color":_vm.isCategoryFilterActive(_vm.auditItem.question.categoryRef[0])
                    ? '#3C3C3B'
                    : '#ECECEB'}})],1),(
                _vm.auditItem.question.vda_process &&
                typeof _vm.auditItem.question.vda_process.name === 'string'
              )?_c('v-chip',{staticClass:"ma-0 mr-1 mb-1 px-2 text-caption flex-shrink-0",class:_vm.isVDAProcessFilterActive(_vm.auditItem.question.vda_process.step)
                  ? ['white--text']
                  : [''],staticStyle:{"max-height":"20px"},style:({
                cursor: _vm.allowFiltering ? 'pointer' : 'default',
              }),attrs:{"label":"","small":"","color":_vm.isVDAProcessFilterActive(_vm.auditItem.question.vda_process.step)
                  ? '#3C3C3B'
                  : '#ECECEB',"ripple":_vm.allowFiltering},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVDAProcess(_vm.auditItem.question.vda_process.step)}}},[_vm._v(" "+_vm._s(_vm.auditItem.question.vda_process.name)+" ")]):_vm._e(),(typeof _vm.auditItem.question.vda_question_scope === 'string')?_c('v-chip',{staticClass:"ma-0 mr-1 mb-1 px-2 text-caption flex-shrink-0",class:_vm.isVDAQuestionScopeFilterActive(
                  _vm.auditItem.question.vda_question_scope
                )
                  ? ['white--text']
                  : [''],staticStyle:{"max-height":"20px"},style:({
                cursor: _vm.allowFiltering ? 'pointer' : 'default',
              }),attrs:{"label":"","small":"","color":_vm.isVDAQuestionScopeFilterActive(
                  _vm.auditItem.question.vda_question_scope
                )
                  ? '#3C3C3B'
                  : '#ECECEB',"ripple":_vm.allowFiltering},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVDAQuestionScope(_vm.auditItem.question.vda_question_scope)}}},[_vm._v(" "+_vm._s(_vm.auditItem.question.vda_question_scope)+" ")]):_vm._e(),_vm._l((_vm.chapterGroups),function(chapterGroup){return _c('div',{key:chapterGroup.standardName,staticClass:"mr-3",style:({
                'max-width': ((100 / _vm.chapterGroups.length) + "%"),
              })},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.showStandardNames)?_c('span',{staticClass:"secondary--text font-weight-medium",staticStyle:{"font-size":"10px","letter-spacing":"0.12rem"}},[_vm._v(" "+_vm._s(chapterGroup.standardName)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((chapterGroup.chapters),function(chapter){return _c('v-chip',{key:chapter.id,staticClass:"ma-0 mr-1 mb-1 px-2 text-caption flex-shrink-0",class:_vm.isChapterFilterActive(_vm.chapterFilterId(chapter))
                        ? 'white--text'
                        : 'lighten-3 accent--text',staticStyle:{"max-height":"20px"},style:({
                      cursor: _vm.allowFiltering ? 'pointer' : 'default',
                    }),attrs:{"label":"","small":"","title":((_vm.$ct(chapter.standardName)) + "\n" + (_vm.$ct(
                      chapter.chapterDescription
                    ))),"color":_vm.isChapterFilterActive(_vm.chapterFilterId(chapter))
                        ? 'accent'
                        : '#E2E0EE',"ripple":_vm.allowFiltering},on:{"click":function($event){$event.stopPropagation();return _vm.toggleChapter(chapter)}}},[_vm._v(" "+_vm._s(chapter.chapterId)+" ")])}),1)])])})],2)]},proxy:true},{key:"header-right",fn:function(){return [_c('div',{staticClass:"d-flex mr-2"},[(
                _vm.allowEditing &&
                !_vm.isTouchDevice &&
                !_vm.disableActions &&
                !_vm.readonly &&
                !_vm.bulkEditMode &&
                hover
              )?_c('div',{staticClass:"d-flex align-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(_vm.noteVisible)?_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 mr-2",attrs:{"icon":"","small":"","color":"grey","disabled":_vm.auditIsBulkUpdating},on:{"click":function($event){$event.stopPropagation();_vm.isEditingNote = true}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center text-no-wrap text-body-2"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-message-text-outline")])],1)]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.edit_notes"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 mr-2",attrs:{"icon":"","small":"","color":"grey","disabled":_vm.auditIsBulkUpdating},on:{"click":function($event){$event.stopPropagation();_vm.isEditingTags = true}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center text-no-wrap text-body-2"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-tag-outline")])],1)])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.edit_tags"))+" ")])])],1):_vm._e(),(_vm.allowEditing && _vm.isManualSelectionStep)?_c('v-tooltip',{attrs:{"disabled":_vm.isTouchDevice || _vm.bulkEditMode,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"disabled":_vm.isTouchDevice || _vm.bulkEditMode},scopedSlots:_vm._u([{key:"default",fn:function(innerScope){return [_c('span',[_c('v-btn',_vm._g(_vm._b({class:{ 'disable-events': _vm.bulkEditMode },attrs:{"icon":"","small":"","disabled":_vm.auditIsBulkUpdating ||
                        _vm.isRestrictPreparationToCategories,"loading":_vm.isToggling,"color":_vm.isIgnored
                          ? innerScope.hover
                            ? 'success'
                            : 'error'
                          : innerScope.hover
                          ? 'error'
                          : 'success'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIgnore(_vm.auditItem.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isIgnored ? innerScope.hover ? "mdi-eye-outline" : "mdi-eye-off-outline" : innerScope.hover ? "mdi-eye-off-outline" : "mdi-eye-outline")+" ")])],1)],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.isIgnored ? hover ? _vm.$ft("views.audit_preparation.include") : _vm.$ft("views.audit_preparation.exclude") : hover ? _vm.$ft("views.audit_preparation.exclude") : _vm.$ft("views.audit_preparation.include"))+" ")])]):_vm._e(),(_vm.allowEditing && _vm.isSelfAssessmentStep)?_c('v-tooltip',{attrs:{"disabled":_vm.isTouchDevice || _vm.bulkEditMode,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"disabled":_vm.isTouchDevice || _vm.bulkEditMode},scopedSlots:_vm._u([{key:"default",fn:function(innerScope){return [_c('span',[_c('v-btn',_vm._g(_vm._b({class:{ 'disable-events': _vm.bulkEditMode },attrs:{"icon":"","small":"","disabled":_vm.auditIsBulkUpdating,"loading":_vm.isTogglingSelfAssessment,"color":!_vm.isSelfAssessmentEnabled
                          ? innerScope.hover
                            ? 'primary'
                            : 'error'
                          : innerScope.hover
                          ? 'error'
                          : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIgnoreSelfAssessment(_vm.auditItem.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(!_vm.isSelfAssessmentEnabled ? innerScope.hover ? "mdi-monitor" : "mdi-monitor-off" : innerScope.hover ? "mdi-monitor-off" : "mdi-monitor")+" ")])],1)],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(!_vm.isSelfAssessmentEnabled ? hover ? _vm.$ft("views.audit_preparation.include") : _vm.$ft("views.audit_preparation.exclude") : hover ? _vm.$ft("views.audit_preparation.exclude") : _vm.$ft("views.audit_preparation.include"))+" ")])]):_vm._e()],1)]},proxy:true}],null,true)},[_c('AQuestionContent',{staticClass:"px-4 pb-2",class:{ 'opacity-4': _vm.isIgnored, 'ml-9': _vm.allowSelection },attrs:{"tags":_vm.auditItem.tags,"labels":_vm.auditItem.labels,"audit-item-proofs":_vm.auditItem.question.requiredProofs,"links":_vm.auditItem.question.links,"hint":_vm.auditItem.question.hint,"text":_vm.auditItem.question.text,"show-self-assessment":_vm.selfAssessmentFeatureEnabled,"self-assessment-text":_vm.selfAssessmentFeatureEnabled && _vm.selfAssessmentIncluded
              ? _vm.auditItem.question.selfAssessmentText
              : undefined,"notes":_vm.questionNotes,"active-filters":_vm.activeFilters,"filtered-text":_vm.filteredText,"search-result":_vm.searchResult,"allow-filtering":_vm.allowFiltering,"question-in-selfassessment":_vm.questionInSelfassessment},on:{"filter-change":_vm.toggleTags}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }